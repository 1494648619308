import {ChainId, Fraction, JSBI, Rounding} from '@pancakeswap/sdk'
import {formatFraction} from '@pancakeswap/utils/formatFractions'
import {ACCESS_RISK_API, ACCESS_TAX_API} from 'config/constants/endpoints'

export const TOKEN_RISK = {
  UNKNOWN: -1,
  VERY_LOW: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  VERY_HIGH: 4,
} as const

export const TOKEN_RISK_MAPPING = {
  '5/5': TOKEN_RISK.VERY_LOW,
  '4/5': TOKEN_RISK.LOW,
  '3/5': TOKEN_RISK.MEDIUM,
  '2/5': TOKEN_RISK.HIGH,
  '1/5': TOKEN_RISK.VERY_HIGH,
} as const

const TOKEN_RISK_TYPE_MAPPING = {
  is_owner: ["Admin privileges abandoned", "Has not dropped admin privileges"],
  is_open_source: ['The contract has not been open-sourced', "Contract source code verified"],

  is_proxy: ["No proxy", "It is a proxy contract, may be potential upgrade and replacement risks"],
  can_take_back_ownership: ["No function found that retrieves ownership", "It is suspected that admin privileges can be recovered"],
  is_honeypot: ["This does not appear to be a honeypot", "It is suspected to be a honeypot"],
  hidden_owner: ["No hidden owner", "Has hidden owner"],
  personal_slippage_modifiable: ["No tax changes found for personal addresses", "Tax changes found for personal addresses"],
  cannot_sell_all: ["Holders can sell all of the token", "Holders can't sell all of the token"],
  anti_whale_modifiable: ["Anti whale can not be modified", "Anti whale can be modified"],
  is_anti_whale: ["No anti_whale(Unlimited number of transactions)", "Anti_whale(Limited number of transactions)"],
  external_call: ["No external call risk found", "External call risk found"],
  selfdestruct: ["This token can not self destruct", "Possible discovery that the token can self-destruct"],

  owner_change_balance: ["Owner can't change balance", "Owner might be able to change the balance", "Due to permission transfer, the balance cannot be modified"],
  is_blacklisted: ["No blacklist", "Blacklist", "Unable to set blacklist due to permission transfer"],
  is_whitelisted: ["No whitelist", "Whitelist", "Due to permission transfer, the whitelist cannot be set"],
  slippage_modifiable: ["Tax cannot be modified", "Possible discovery of editable tax", "Unable to set transaction tax due to permission transfer"],
  is_mintable: ["Can not Mint", "Can mint, issuance maliciously", "Due to permission transfer, additional issuance cannot be carried out"],
  trading_cooldown: ["No trading cooldown function", "Has trading-cool-down mechanism that can limit the minimum time between two transactions", "Unable to set transaction cooldown due to permission transfer"],
  transfer_pausable: ["No codes found to suspend trading", "Possible discovery of pause trading function", "Due to permission transfer, transaction suspension cannot be set"],
}

export interface RiskTokenInfo {
  address: string
  chainId: ChainId
  riskLevel: (typeof TOKEN_RISK)[keyof typeof TOKEN_RISK]
  buyTax: string
  sellTax: string
  warnList: (string)[]
  successList: (string)[]
}

interface TokenPair {
  symbol: string
  count: string
  isMain: boolean
}

export interface TaxTokenInfo {
  address: string
  chainId: ChainId
  buyTax: number
  sellTax: number
  transferTax: number
  totalHolders: number
  pairAddress: string
  tokenPair: (TokenPair)[]
  liquidity: number
}

const fetchRiskApi = async (address: string, chainId: number) => {
  const response = await fetch(`${ACCESS_RISK_API}/${chainId}/${address}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Client-ID': 'muskswap'
    },
  })

  return response.json()
}

function parseNumber(num: string | number, decimals: number): string {
  const PRECISION = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
  const tmp = new Fraction(JSBI.BigInt(num), PRECISION)
  return formatFraction(
    tmp,
    decimals,
    Rounding.ROUND_DOWN
  )
}

export const fetchTaxToken = async (address: string, chainId: number): Promise<TaxTokenInfo> => {
  const response = await fetch(`${ACCESS_TAX_API}/${chainId}/${address}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Client-ID': 'muskswap'
    },
  })
  const result = await response.json()

  const buyTax = parseFloat(Number(result?.simulationResult?.buyTax ?? 0).toFixed(2))
  const sellTax = parseFloat(Number(result?.simulationResult?.sellTax ?? 0).toFixed(2))
  const transferTax = parseFloat(Number(result?.simulationResult?.transferTax ?? 0).toFixed(2))
  const pair = result?.pair
  const sPair = pair?.pair
  const pairAddress = sPair?.address ?? ''
  const pairs = []
  let totalHolders

  if(pair && sPair) {
    for (const i of [result?.token, result?.withToken]) {
      if (i) {
        const adr = String(i.address).toLowerCase()
        const decimals = i?.decimals ?? 0
        const symbol = i?.symbol ?? i?.name ?? 'UNKNOWN'
        const item: TokenPair = {
          symbol,
          count: "",
          isMain: false
        }

        if (String(sPair.token0).toLowerCase() === adr) {
          item.count = parseNumber(pair?.reserves0 ?? 0, decimals)
        }

        if (String(sPair.token1).toLowerCase() === adr) {
          item.count = parseNumber(pair?.reserves1 ?? 0, decimals)
        }

        if (adr === address.toLowerCase()) {
          totalHolders = i?.totalHolders ?? 0
          item.isMain = true
        }

        pairs.push(item)
      }
    }
  }


  return {
    address,
    chainId,
    buyTax,
    sellTax,
    transferTax,
    totalHolders,
    pairAddress,
    tokenPair: pairs,
    liquidity: pair?.liquidity ?? 0
  }
}

export const fetchRiskToken = async (address: string, chainId: number): Promise<RiskTokenInfo> => {
  const result = await fetchRiskApi(address, chainId)
  const checkList = Object.keys(TOKEN_RISK_TYPE_MAPPING).filter(i => !['is_owner', 'is_open_source', 'can_take_back_ownership'].includes(i))
  const warnList = []
  const successList = []
  const blackAdr = ['0x0000000000000000000000000000000000000000', '0x000000000000000000000000000000000000dead', '0x0000000000000000000000000000000000000001']
  const isOwner = result.owner_address && !blackAdr.includes(String(result.owner_address).toLowerCase())
  const canTakeBackOwner = Number(result?.can_take_back_ownership || 0)
  let isCheat = false


  if (Number(result.is_open_source || 0)) {
    successList.push(TOKEN_RISK_TYPE_MAPPING.is_open_source[1])
  } else {
    warnList.push(TOKEN_RISK_TYPE_MAPPING.is_open_source[0])
  }

  if (isOwner) {
    warnList.push(TOKEN_RISK_TYPE_MAPPING.is_owner[1])
  } else {
    successList.push(TOKEN_RISK_TYPE_MAPPING.is_owner[0])

    if (canTakeBackOwner) {
      warnList.push(TOKEN_RISK_TYPE_MAPPING.can_take_back_ownership[1])
    } else {
      successList.push(TOKEN_RISK_TYPE_MAPPING.can_take_back_ownership[0])
    }
  }


  for (const i of checkList) {
    const val = result[i]
    if (val) {
      const k = Number(val)
      if (k && isOwner) {
        warnList.push(TOKEN_RISK_TYPE_MAPPING[i][k])
      } else if (k) {
        const flag = TOKEN_RISK_TYPE_MAPPING[i][k + 1]
        if (flag) {
          successList.push(TOKEN_RISK_TYPE_MAPPING[i][k + 1])
          isCheat = true
        } else {
          warnList.push(TOKEN_RISK_TYPE_MAPPING[i][k])
        }
      } else {
        successList.push(TOKEN_RISK_TYPE_MAPPING[i][k])
      }
    }
  }

  const isTrust = result?.trust_list ?? false

  return {
    address,
    chainId,
    riskLevel: TOKEN_RISK_MAPPING[!isTrust && warnList.length > 0 ? "1/5" : isCheat ? "4/5" : "5/5"],
    buyTax: result.buy_tax,
    sellTax: result.sell_tax,
    warnList,
    successList
  }
}
