import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 46 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path transform="scale(0.8)" d="M22.9091 0L43.8251 4.648C44.9884 4.90764 45.8182 5.93855 45.8182 7.13236V32.5538C45.8182 37.66 43.2651 42.4302 39.0167 45.2607L22.9091 56L6.80145 45.2607C2.55055 42.4276 0 37.66 0 32.5564V7.13236C0 5.93855 0.829818 4.90764 1.99309 4.648L22.9091 0ZM34.2415 18.3833L21.6415 30.9807L14.4429 23.7822L10.8436 27.3815L21.644 38.1818L37.8433 21.9825L34.2415 18.3833Z" fill="#32C800"/>
        </Svg>
    );
};

export default Icon;
