import {Trans, useTranslation} from '@pancakeswap/localization'
import {Currency, ERC20Token, Token} from '@pancakeswap/sdk'
import isUndefinedOrNull from '@pancakeswap/utils/isUndefinedOrNull'
import {
  Button,
  Dots,
  Flex,
  HelpIcon,
  IconButton,
  Link,
  RefreshIcon,
  Tag,
  Text,
  useTooltip,
  promotedGradient,
  RiskSuccessIcon,
  RiskWarnIcon,
  AutoColumn,
  RowBetween,
  CopyButton
} from '@pancakeswap/uikit'
import {useEffect, useMemo, useState} from 'react'
import {useUserTokenRisk} from 'state/user/hooks/useUserTokenRisk'
import {useUserTokenTax, useUserTokenTaxA, useUserTokenTaxB} from 'state/user/hooks/useUserTokenTax'
import {useAllLists} from 'state/lists/hooks'
import styled from 'styled-components'
import useSWRImmutable from 'swr/immutable'
import {fetchRiskToken, fetchTaxToken, TOKEN_RISK} from 'views/Swap/hooks/fetchTokenRisk'
import {SwapInfoLabel} from "@pancakeswap/uikit/src/widgets/Swap/SwapInfo";

const AnimatedButton = styled(Button)`
  animation: ${promotedGradient} 1.5s ease infinite;
  background-size: 1000% 1000%;
`

interface AccessRiskProps {
  token: Currency | ERC20Token
  simpleMode?: boolean
  currencyType?: number
  showRisk?: boolean
  showTax?: boolean
}

function RetryRisk({onClick}: { onClick: () => void }) {
  const [retry, setRetry] = useState(false)
  const {t} = useTranslation()
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)
  const displayTooltip = () => {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }
  const retryTooltip = useTooltip(
    <>
      {t('Risk scanning failed.')} {!retry && t('Press the button to retry.')}
    </>,
    {
      placement: 'auto',
      manualVisible: true,
      trigger: 'hover',
    },
  )
  return (
    <div ref={retryTooltip.targetRef}>
      <IconButton
        ml="4px"
        onClick={() => {
          setRetry(true)
          displayTooltip()
          onClick()
        }}
        disabled={retry}
        variant="text"
        size="sm"
        style={{width: '20px'}}
        height="20px"
      >
        <RefreshIcon color="primary" width="16px" height="16px"/>
      </IconButton>
      {isTooltipDisplayed && retryTooltip.tooltip}
    </div>
  )
}

export function useTokenRisk(token?: Token) {
  return useSWRImmutable(token && token.address && ['risk', token.chainId, token.address], () => {
    return fetchRiskToken(token.address, token.chainId)
  }, {
    revalidateOnMount: true
  })
}

export function useTokenTax(token?: Token) {
  return useSWRImmutable(token && token.address && ['tax', token.chainId, token.address], () => {
    return fetchTaxToken(token.address, token.chainId)
  }, {
    refreshInterval: 5000,
    revalidateOnMount: true
  })
}

function checkAccessShow(token): boolean {
  if (token?.isNative) return false
  if (token?.isToken) {
    const blackAdr = token.chainId === 1 ? ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0xdAC17F958D2ee523a2206206994597C13D831ec7'] : token.chainId === 56 ? ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0x55d398326f99059fF775485246999027B3197955'] : []
    const adr = token.address.toLowerCase()
    return !blackAdr.some(i => String(i).toLowerCase() === adr)
  }
  return false
}

const AccessRisk: React.FC<AccessRiskProps> = ({token, simpleMode, currencyType}) => {
  const [showRisk] = useUserTokenRisk()
  const [showTax] = useUserTokenTax()
  const [_a, setTaxA] = useUserTokenTaxA()
  const [_b, setTaxB] = useUserTokenTaxB()
  const show = checkAccessShow(token)
  useEffect(() => {
    if (!show) {
      if (currencyType === 1) {
        setTaxA(`${token.wrapped.address}-0`)
      } else if (currencyType === 2) {
        setTaxB(`${token.wrapped.address}-0`)
      }
    }
  }, [currencyType, setTaxA, setTaxB, show, token.wrapped.address])

  if (!showRisk && !showTax) return null

  return show &&
    <AccessRiskComponent token={token as ERC20Token} showRisk={showRisk} showTax={showTax}
                         simpleMode={simpleMode || false} currencyType={currencyType}/>
}

function formatNumber(num: string | number): string {
  let number = parseFloat(`${num}`)
  const units = ["", "K", "M", "G", "T", "P", "E", "B"];
  const divisor = 1000;
  let unitIndex = 0;

  while (number >= divisor && unitIndex < units.length - 1) {
    number /= divisor;
    unitIndex++;
  }

  return number.toFixed(2) + units[unitIndex];
}

const TOKEN_RISK_T = {
  [TOKEN_RISK.VERY_LOW]: <Trans>Very Low Risk</Trans>,
  [TOKEN_RISK.LOW]: <Trans>Low Risk</Trans>,
  [TOKEN_RISK.MEDIUM]: <Trans>Medium Risk</Trans>,
  [TOKEN_RISK.HIGH]: <Trans>High Risk</Trans>,
  [TOKEN_RISK.VERY_HIGH]: <Trans>Existing Risk</Trans>,
  [TOKEN_RISK.UNKNOWN]: <Trans>Unknown Risk</Trans>,
} as const

const RiskTooltipBox = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
`

const PoolBox = styled.div`
  margin-right: 8px;
  margin-bottom: 16px;
  width: 320px;
  border: 2px solid ${({theme}) => theme.colors.textSubtle};
  border-radius: 15px;
`

const PoolBoxItem = styled(Text)`
  width: calc(100% / 3);
`


const AccessRiskComponent: React.FC<AccessRiskProps> = ({token, simpleMode, currencyType, showRisk, showTax}) => {
  const _token = token as ERC20Token
  const {t} = useTranslation()
  const [_a, setTaxA] = useUserTokenTaxA()
  const [_b, setTaxB] = useUserTokenTaxB()

  const {data: riskData, error: riskError, mutate: riskMutate} = useTokenRisk(showRisk ? _token : null)
  const {data: taxData} = useTokenTax(showTax ? _token : null)

  const lists = useAllLists()
  const tokenInLists = useMemo(() => {
    if (!_token?.address) return false
    const tokenLists = Object.values(lists)
      .map((list) => list?.current?.tokens)
      .filter(Boolean)
    if (!tokenLists.length) return null
    return tokenLists.some((tokenInfoList) => {
      return tokenInfoList.some((tokenInfo) => tokenInfo.address === _token.address)
    })
  }, [lists, _token?.address])

  const warnList = useMemo(() => {
    if (riskData) {
      return riskData.warnList
    }
    return []
  }, [riskData])

  const successList = useMemo(() => {
    if (riskData) {
      return riskData.successList
    }
    return []
  }, [riskData])

  const tokenPairA = useMemo(()=> {
    if(taxData?.tokenPair) {
      const item = taxData.tokenPair.filter(i => i.isMain)[0]
      if(item) {
        return {
          symbol: item.symbol,
          num: item.count ? formatNumber(item.count) : '--'
        }
      }
      return null
    }
    return null
  }, [taxData])

  const tokenPairB = useMemo(()=>{
    if(taxData?.tokenPair) {
      const item = taxData.tokenPair.filter(i => !i.isMain)[0]
      if(item) {
        return {
          symbol: item.symbol,
          num: item.count ? formatNumber(item.count) : '--'
        }
      }
      return null
    }
    return null
  },[taxData])


  useEffect(() => {
    if (taxData && currencyType === 1) {
      const val = Number.parseInt((taxData.sellTax * 100).toString())
      setTaxA(`${_token.address}-${val}`)
    } else if (taxData && currencyType === 2) {
      const val = Number.parseInt((taxData.buyTax * 100).toString())
      setTaxB(`${_token.address}-${val}`)
    }
  }, [taxData, setTaxA, setTaxB, currencyType, _token.address])


  const {targetRef, tooltip, tooltipVisible} = useTooltip(
    <>
      <RiskTooltipBox>
        {showTax && (
          <>
            <PoolBox>
              <AutoColumn gap="2px" py="8px" px="16px">
                <RowBetween alignItems="center">
                  <PoolBoxItem bold>{t('Pair')}</PoolBoxItem>
                  <PoolBoxItem bold textAlign="center">{t('Amount')}</PoolBoxItem>
                  <PoolBoxItem bold textAlign="right">{t('Liquidity')}</PoolBoxItem>
                </RowBetween>
                <RowBetween alignItems="center">
                  <PoolBoxItem>
                    <Text small ellipsis>{tokenPairA?.symbol ?? 'UNKNOWN'}</Text>
                    <Text small ellipsis>{tokenPairB?.symbol ?? 'UNKNOWN'}</Text>
                  </PoolBoxItem>
                  <PoolBoxItem textAlign="center">
                    <Text small ellipsis>{tokenPairA?.num ?? '--'}</Text>
                    <Text small ellipsis>{tokenPairB?.num ?? '--'}</Text>
                  </PoolBoxItem>
                  <PoolBoxItem small ellipsis textAlign="right">${taxData?.liquidity ? formatNumber(taxData.liquidity) : '--'}</PoolBoxItem>
                </RowBetween>

                <Text small mt="18px">{t('Holders')}: {taxData?.totalHolders > 0 ? taxData.totalHolders : '--'}</Text>

                <RowBetween alignItems="center">
                  <Text small
                        ellipsis>{t('Pair')}: {taxData?.pairAddress ? `${taxData.pairAddress.substring(0, 4)}...${taxData.pairAddress.substring(taxData.pairAddress.length - 6)}` : '--'}</Text>
                  {taxData?.pairAddress && (
                    <CopyButton
                      width="14px"
                      buttonColor="textSubtle"
                      text={taxData.pairAddress}
                      tooltipMessage={t('Pair address copied')}
                    />
                  )}
                </RowBetween>
              </AutoColumn>
            </PoolBox>
          </>
        )}


        {showRisk && (
          <>
            <Text mb="12px">
              {t('Risk scan results are provided by a third party')}
              <Link style={{display: 'inline'}} ml="2px" external href="https://gopluslabs.io/">GOPULUS</Link>
            </Text>

            {
              warnList.map(i => (
                <Flex mb="6px" key={i}>
                  <RiskWarnIcon/>
                  <Text ml="4px" bold small>{t(i)}</Text>
                </Flex>
              ))
            }
            {
              successList.map(i => (
                <Flex mb="6px" key={i}>
                  <RiskSuccessIcon/>
                  <Text ml="4px" bold small>{t(i)}</Text>
                </Flex>
              ))
            }

            <Flex mt="4px">
              <Text>{t('Learn more about risk rating')}</Text>
              <Link ml="4px" external href={`https://gopluslabs.io/token-security/${_token.chainId}/${_token.address}`}>
                {t('here.')}
              </Link>
            </Flex>
          </>
        )}

      </RiskTooltipBox>

    </>,
    {placement: 'bottom'},
  )

  const riskLevel = useMemo(() => {
    if (!isUndefinedOrNull(riskData?.riskLevel)) {
      if (tokenInLists || riskData.riskLevel > TOKEN_RISK.MEDIUM) {
        return riskData.riskLevel
      }
      return TOKEN_RISK.UNKNOWN
    }
    return undefined
  }, [riskData, tokenInLists])


  if (riskData || taxData) {
    const hasRiskValue = TOKEN_RISK_T[riskLevel]

    return (
      <Flex justifyContent="space-between" alignItems="center">

        {!simpleMode && taxData ? (
          <Flex justifyContent="flex-start" flex={2} flexDirection="row" alignItems="center">
            <SwapInfoLabel>{t('Buy')}{taxData.buyTax ?? '--'}% {t('Sell')}{taxData.sellTax ?? '--'}% {t('Transfer')}{taxData.transferTax ?? '--'}%</SwapInfoLabel>
          </Flex>) : null}


        <Flex justifyContent="flex-end" flex={1}>
          <div ref={targetRef} style={{userSelect: 'none'}}>
            <Tag
              variant={showRisk && riskData ? riskData.riskLevel > TOKEN_RISK.MEDIUM ? 'failure' : !tokenInLists ? 'textDisabled' : 'primary' : 'textDisabled'}>
              <Text bold small color="invertedContrast">
                {hasRiskValue ?? (showRisk ? t('Scanning') : (<Trans>Pool</Trans>))}
              </Text>
              {tooltipVisible && tooltip}
              <Flex>
                <HelpIcon ml="4px" width="16px" height="16px" color="invertedContrast"/>
              </Flex>
            </Tag>
          </div>
        </Flex>
      </Flex>
    )
  }

  if (riskError) {
    return (
      <Flex justifyContent="flex-end" alignItems="center">
        <div ref={targetRef} style={{userSelect: 'none'}}>
          <Tag variant="textDisabled">
            <Text bold small color="invertedContrast">
              {t('Unknown')}
            </Text>
            {tooltipVisible && tooltip}
            <Flex>
              <HelpIcon ml="4px" width="16px" height="16px" color="invertedContrast"/>
            </Flex>
          </Tag>
        </div>
        <RetryRisk
          onClick={() => riskMutate()}
          // key for resetting retry state
          key={_token.chainId + _token.address}
        />
      </Flex>
    )
  }


  return (
    <>
      <Flex justifyContent="flex-end">
        <AnimatedButton variant="bubblegum" scale="sm" style={{textTransform: 'uppercase', height: '28px'}}>
          <Dots style={{fontSize: '14px'}}>{t('Scanning')}</Dots>
        </AnimatedButton>
        {tooltipVisible && tooltip}
        <Flex ref={targetRef}>
          <HelpIcon ml="4px" width="20px" height="20px" color="textSubtle"/>
        </Flex>
      </Flex>
    </>
  )
}

export default AccessRisk
