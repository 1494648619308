import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 46 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path transform="scale(0.8)" d="M23.7273 0L44.6433 4.648C45.8065 4.90764 46.6364 5.93855 46.6364 7.13236V32.5538C46.6364 37.66 44.0833 42.4302 39.8349 45.2607L23.7273 56L7.61963 45.2607C3.36872 42.4276 0.818176 37.66 0.818176 32.5564V7.13236C0.818176 5.93855 1.64799 4.90764 2.81127 4.648L23.7273 0ZM28.8182 20.3636V12.7273H18.6364V26.7273V30.5455L23.7273 34.3636L18.6364 38.1818V43.2727H23.7273H28.8182V38.1818L23.7273 34.3636L28.8182 30.5455V26.7273V20.3636Z" fill="#FFC700"/>
        </Svg>
    );
};

export default Icon;
